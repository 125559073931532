const middleware = {}

middleware['experienceRedirect'] = require('../middleware/experienceRedirect.ts')
middleware['experienceRedirect'] = middleware['experienceRedirect'].default || middleware['experienceRedirect']

middleware['iframeSupport'] = require('../middleware/iframeSupport.ts')
middleware['iframeSupport'] = middleware['iframeSupport'].default || middleware['iframeSupport']

middleware['jsessionid'] = require('../middleware/jsessionid.ts')
middleware['jsessionid'] = middleware['jsessionid'].default || middleware['jsessionid']

middleware['need-login-white-label'] = require('../middleware/need-login-white-label.ts')
middleware['need-login-white-label'] = middleware['need-login-white-label'].default || middleware['need-login-white-label']

middleware['needLogin'] = require('../middleware/needLogin.ts')
middleware['needLogin'] = middleware['needLogin'].default || middleware['needLogin']

middleware['orderDetailDeeplinkRedirect'] = require('../middleware/orderDetailDeeplinkRedirect.ts')
middleware['orderDetailDeeplinkRedirect'] = middleware['orderDetailDeeplinkRedirect'].default || middleware['orderDetailDeeplinkRedirect']

middleware['paymentNeedLogin'] = require('../middleware/paymentNeedLogin.ts')
middleware['paymentNeedLogin'] = middleware['paymentNeedLogin'].default || middleware['paymentNeedLogin']

middleware['previewRedirect'] = require('../middleware/previewRedirect.ts')
middleware['previewRedirect'] = middleware['previewRedirect'].default || middleware['previewRedirect']

export default middleware
