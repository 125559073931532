/**
 * 结算页面专用 needLogin middleware
 */
import { Middleware } from '@nuxt/types'
import api from '~/share/data/apis'

const middleware: Middleware = async function (ctx, next) {
  const { klook } = ctx.store.state
  const lang = klook.language
  let path = 'signin'

  if (klook.utilConfig?.is_guest_checkout) {
    path = 'guest_booking'
  }

  const backUrl = `signin_jump=${encodeURIComponent(ctx.route.fullPath)}`
  const url = lang === 'en' ? `/${path}/?${backUrl}` : `/${lang}/${path}/?${backUrl}`

  try {
    const resp = await ctx.app.$axios.$get(api.verify)
    if (!resp?.success) {
      const query = { shopping_cart_guid: ctx.query.shoppingcart_guid }
      // 登录态失效，调用接口判断是否可以可以不登陆就进结算页
      const checkoutResp = await ctx.app.$axios.$get(api.can_simple_checkout, { params: query })
      // 如果返回结果为true，则可以简化结算流程，无需登录
      if (!checkoutResp?.result) {
        ctx.redirect(url)
      }
      ctx.store.commit('auth/SET_SIMPLECHECKOUT_RESULT', {
        simpleCheckout: checkoutResp.result,
        isLoggedIn: resp?.success
      })
    }
  } catch (_error) {
    // 接口异常，重定向到登录页面
    ctx.redirect(url)
  }

  next()
}

export default middleware
