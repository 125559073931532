import { Method } from 'axios'

interface ReqLogObj {
  requestId: string
  method: Method,
  url: string
}

interface ResLogObj extends ReqLogObj {
  status: string
  elapsedTime: number,
}

interface ErrorLogObj extends ResLogObj {
  message: string
}

export const setOpenHarmonyParams = (config: any) => {
  const type = config.method.toLocaleLowerCase()
  if (!['get', 'post'].includes(type)) {
    // 目前仅支持：get和post请求
    return
  }
  const typeMaps: any = {
    get: 'params',
    post: 'data'
  }
  const obj = config[typeMaps[type]]
  if (obj?.platform === 'mobile') {
    obj.platform = 'openharmony-shell'
  }
}

export function formatReq(logObj: ReqLogObj) {
  let format = '-> %requestId %method API %url'

  format = format.replace(/%requestId/g, logObj.requestId)
  format = format.replace(/%method/g, logObj.method.toUpperCase())
  format = format.replace(/%url/g, logObj.url)

  return format
}

export function formatRes(logObj: ResLogObj) {
  let format = '<- %requestId %method API %url %status %elapsedTime'

  format = format.replace(/%requestId/g, logObj.requestId)
  format = format.replace(/%method/g, logObj.method.toUpperCase())
  format = format.replace(/%url/g, logObj.url)
  format = format.replace(/%status/g, logObj.status)
  format = format.replace(/%elapsedTime/g, `${logObj.elapsedTime}ms`)

  return format
}

export function formatError(logObj: ErrorLogObj) {
  let format = '<- %requestId %method API %url %status %elapsedTime %message'

  format = format.replace(/%requestId/g, logObj.requestId)
  format = format.replace(/%method/g, logObj.method.toUpperCase())
  format = format.replace(/%url/g, logObj.url)
  format = format.replace(/%status/g, logObj.status)
  format = format.replace(/%elapsedTime/g, `${logObj.elapsedTime}ms`)
  format = format.replace(/%message/g, logObj.message)

  return format
}

export function getHref(config: any) {
  return config.baseURL + config.url
}

function getMeta(metas: any[]) {
  const arr = Array.isArray(metas) ? metas : [metas]
  return Object.assign({}, ...arr)
}

export function formatToOptimus(ctx: any, other: any = {}, stringify = true) {
  const { config = {}, request = {}, ctx: { route, req }, response } = ctx
  const status = response?.status || ''
  const targetMatch = config.url?.match(/\/v\d\/(\w+)\//i) || []
  const meta = getMeta(route?.meta || [])

  let reqPath = config.url || config.fullURL! || ''
  try {
    reqPath = String(reqPath).split('?')[0]
  } catch (e) {}
  const regularUrl = config.regularUrl || reqPath || 'global_url'
  const logData = {
    // type,
    level: 'I',
    action: 0,
    _regular_url_: regularUrl,
    keplerId: req.keplerId,
    url: reqPath || config.url || config.fullURL!,
    target_srv: targetMatch[1] || 'unknown',
    req_status: String(status),
    importance: config.importance || 5,
    _page_: meta.trackedPageName || route?.name || 'global_page',
    method: String(config.method!).toLowerCase(),
    _duration_: Date.now() - config.startTime!,
    request_id: config.requestId!,
    send_bytes: (request.socket || {}).bytesWritten || 0,
    received_bytes: (request as any).length || (request.socket || {}).bytesRead || 0,
    pageUrl: req.oldPath || req.url || '',
    _logger_name_: 'access_log:frontend:node:api',
    business: 'klook-nuxt-web/axios',
    ...other
  }
  return stringify ? JSON.stringify(logData) : logData
}
