import isEmpty from 'lodash/isEmpty'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { Context } from '@nuxt/types'
import { getSupportLanguageList, convertToBackendCode, supportLanguageList } from '~/share/data/language'
import { isSearchBot } from '~/share/utils'
import { state as getInitialExperienctState } from '~/store/experience'

import { platform } from '~/share/data/cms'
import { cms } from '~/share/data/cms/types'

interface ClientData {
  ip: string
  isIOS: boolean
  pageTemplate: string
  pageId: string
  residenceCode: Data.Country
  residenceId: number
}

export const state = () => ({
  klook: {
    language: 'en',
    backendLanguage: 'en_BS',
    supportLanguages: supportLanguageList,
    currency: 'USD',
    currencySymbol: 'US$',
    ip: '',
    ipCountry: 'US',
    residenceCode: 'US',
    residenceId: 0,
    platform: 'mobile',
    isOpenHarmony: false,
    isIOS: false,
    isKlookApp: false,
    webp: 0,
    retina: 0,
    utilConfig: {},
    pageId: '',
    pageTemplate: 'NA',
    // currencyRate: {},
    secondaryNav: {},
    destinationData: {},
    allCategoryList: [],
    isBot: false,
    platformMp: ''
  } as Data.Klook
})
export interface RootState {
  klook: Data.Klook,
  experience: any
  hotel: any
}
// export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  language: state => state.klook.language,
  currencySymbol: state => state.klook.currencySymbol,
  platform: state => state.klook.platform,
  isDesktop: state => state.klook.platform === 'desktop',
  isMobile: state => state.klook.platform === 'mobile',
  isGuestCheckout: state => state.klook.utilConfig.is_guest_checkout,
  whiteLabelConfig: state => state.klook?.utilConfig?.whiteLabelUtilConfig,
  useCredits: state => state.klook.utilConfig.use_credits,
  languagePath: state => state.klook.language === 'en' ? '' : `/${state.klook.language}`,
  mapType: state => state.klook.ipCountry === 'CN' ? 'mapbox' : 'google',
  isEUArea: (state) => {
    const EUArea = [
      'AT', 'BE', 'BG', 'CZ', 'CY', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'GB', 'UK', 'IS', 'NO', 'RU'
    ]
    return EUArea.includes(state.klook.ipCountry.toLocaleUpperCase())
  },
  // 时间格式化
  // 除了en-US，其他语言下都使用24小时制
  timeFormatString: (state) => {
    const language = state.klook.language
    const isUSEN = language === 'en' || language === 'en-US'
    return isUSEN ? 'hh:mm A' : 'HH:mm'
  }
}

export const mutations: MutationTree<RootState> = {
  SET_INITIAL_DATA(state, klook) {
    if (klook.language) {
      klook.backendLanguage = convertToBackendCode(klook.language) || ''
    }

    state.klook = { ...state.klook, ...klook }
  },

  SET_LANGUAGE(state, language: Data.Language) {
    state.klook.language = language
  },
  SET_LANGUAGES(state, languages: Data.Language[]) {
    // 需要和站点支持的语言取交集
    state.klook.supportLanguages = state.klook.websiteConfig.support_languages.filter((v: Data.Language) => languages.includes(v))
  },
  SET_CURRENCY(state, currency: Data.Currency) {
    state.klook.currency = currency
  },
  SET_SECONDARY_NAV(state, secondaryNav: any) {
    state.klook.secondaryNav = secondaryNav
  },
  SET_DESTINATION_DATA(state, destinationData: any) {
    state.klook.destinationData = destinationData
  },
  SET_ALL_CATEGORY_LIST(state, allCategoryList: any) {
    state.klook.allCategoryList = allCategoryList
  },
  INITIAL_STATE(state) {
    state.experience && (state.experience = getInitialExperienctState())
  },
  SET_CLIENT_INIT_DATA(state, payload: ClientData) {
    state.klook.ip = payload.ip
    state.klook.isIOS = payload.isIOS
    state.klook.pageId = payload.pageId
    state.klook.residenceCode = payload.residenceCode
    state.klook.residenceId = payload.residenceId
  },
  SET_PAGE_TEMPLATE(state, template: string = 'NA') {
    state.klook.pageTemplate = template
  }
}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit({ commit }, ctx: Context) {
    const { req } = ctx

    if (req.isStaticOrApi) {
      return
    }

    const utilConfig = req.utilConfig || {}
    const isCMCC = utilConfig.util_name === 'cmcc'
    const isBot = isSearchBot(req.headers['user-agent'])

    function getHost() {
      if (process.env.APP_ENV === 'production') {
        return req.realHost
      } else {
        const host = ctx.route.query.host
        if (host) {
          ctx.app.$cookies.set('x-klook-host', host, {
            path: '/',
            expires: new Date(Date.now() + 2 * 365 * 24 * 3600 * 1000),
            httpOnly: true
          })
          return host
        } else {
          return (
            ctx.app.$cookies.get('x-klook-host') || req.realHost
          )
        }
      }
    }

    const host = getHost()

    commit('SET_INITIAL_DATA', {
      language: isCMCC ? 'zh-CN' : req.language,
      supportLanguages: getSupportLanguageList(req.websiteConfig.website),
      currency: isCMCC ? 'CNY' : req.currency,
      currencySymbol: req.currencySymbol,
      // ip: req.ip,
      ipCountry: req.country,
      residenceCode: req.residenceCode,
      residenceId: req.residenceId,
      platform: req.platform,
      isOpenHarmony: req.isOpenHarmony,
      // isIOS: req.isIOS,
      isKlookApp: req.isKlookApp,
      webp: req.webp,
      retina: req.retina,
      utilConfig,
      affiliateConf: req.affiliateConf || {},
      qsAffiliateConf: req.qsAffiliateConf || {},
      host,
      // pageId: req.pageId,
      websiteConfig: req.websiteConfig,
      marketId: req.websiteConfig?.market_id || 1,
      market: req.websiteConfig?.market || 'global',
      isBot,
      platformMp: req.platformMp,
      keplerId: req.keplerId
    })

    // Set Kepler common
    if (!isBot) {
      commit('common/kepler/SET_KEPLER_ID', req.keplerId)
      commit('common/kepler/SET_EXPERIMENTS_HIT_LIST', req.experimentsHitList)
    }
    await null
  },

  syncLanguage({ commit }, language: string) {
    commit('SET_LANGUAGE', language)
  },

  syncSupportLanguages({ commit }, languages: Data.Language[]) {
    commit('SET_LANGUAGES', languages)
  },

  getCMSGenericItemList({ state }, payload: { type: cms.cmsType, options: cms.options }) {
    const { type, options = {} } = payload
    if (!options.platform) {
      options.platform = state.klook.platform === 'mobile' ? platform.MOBILE : platform.WEB
    }

    return this.$axios
      .$get(`/v1/usrcsrv/generic/types/${type}`, { params: options, timeout: 1000, regularUrl: '/v1/usrcsrv/generic/types/{*}' })
      .then((res) => {
        if (res.success && !isEmpty(res.result)) {
          return res.result[0].content
        }
      })
      .catch(() => {
        return {}
      })
  },

  async getDestinationData({ commit }) {
    await this.$axios
      .$get('/v2/usrcsrv/destination/guide')
      .then((res) => {
        let destinationData = {}
        if (res.success && !isEmpty(res.result)) {
          destinationData = res.result
        }
        commit('SET_DESTINATION_DATA', destinationData)
      })
      .catch(() => {
        return {}
      })
  },

  async getAllCategoryList({ commit }) {
    await this.$axios
      .$get('/v1/usrcsrv/menu/category')
      .then((res) => {
        let allCategoryList = {}
        if (res.success && !isEmpty(res.result)) {
          allCategoryList = res.result.menus
        }
        commit('SET_ALL_CATEGORY_LIST', allCategoryList)
      })
      .catch(() => {
        return {}
      })
  }
}
