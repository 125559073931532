import Vue from 'vue'
import Router from 'vue-router'
import scrollBehavior from './router.scrollBehavior.js'

Vue.use(Router)

const interopDefault = function (promise) {
  return promise.then(m => m.default || m)
}

const pagesCommonError404 = () => interopDefault(import('~/pages/common/error/404.ts' /* webpackChunkName: "pages-common-error-404" */))
const pagesCommonError500 = () => interopDefault(import('~/pages/common/error/500.ts' /* webpackChunkName: "pages-common-error-500" */))
const pagesCommonApiHealthCheck = () => interopDefault(import('~/pages/common/apiHealthCheck.ts' /* webpackChunkName: "pages-common-apiHealthCheck" */))
const pagesExperienceHomeDesktopHome = () => interopDefault(import('~/pages/experience/home/desktop/home.vue' /* webpackChunkName: "pages-experience-home-desktop-home" */))
const pagesExperienceHomeMobileHome = () => interopDefault(import('~/pages/experience/home/mobile/home.vue' /* webpackChunkName: "pages-experience-home-mobile-home" */))
const pagesExperienceAppRedirectIndex = () => interopDefault(import('~/pages/experience/app-redirect/index.vue' /* webpackChunkName: "pages-experience-app-redirect-index" */))
const pagesExperienceVoucherRedeemMobileIndex = () => interopDefault(import('~/pages/experience/voucher-redeem/mobile/index.vue' /* webpackChunkName: "pages-experience-voucher-redeem-mobile-index" */))
const pagesTourHomeDesktopHome = () => interopDefault(import('~/pages/tour/home/desktop/home.vue' /* webpackChunkName: "pages-tour-home-desktop-home" */))
const pagesTourHomeMobileHome = () => interopDefault(import('~/pages/tour/home/mobile/home.vue' /* webpackChunkName: "pages-tour-home-mobile-home" */))
const pagesExperienceHomeDesktopCategoryActivity = () => interopDefault(import('~/pages/experience/home/desktop/category-activity.vue' /* webpackChunkName: "pages-experience-home-desktop-category-activity" */))
const pagesExperienceHomeMobileCategoryActivity = () => interopDefault(import('~/pages/experience/home/mobile/category-activity.vue' /* webpackChunkName: "pages-experience-home-mobile-category-activity" */))
const pagesExperienceHomeDesktopSearch = () => interopDefault(import('~/pages/experience/home/desktop/search.vue' /* webpackChunkName: "pages-experience-home-desktop-search" */))
const pagesExperienceHomeMobileSearch = () => interopDefault(import('~/pages/experience/home/mobile/search.vue' /* webpackChunkName: "pages-experience-home-mobile-search" */))
const pagesExperienceHomeDesktopNearby = () => interopDefault(import('~/pages/experience/home/desktop/nearby.vue' /* webpackChunkName: "pages-experience-home-desktop-nearby" */))
const pagesExperienceHomeMobileNearby = () => interopDefault(import('~/pages/experience/home/mobile/nearby.vue' /* webpackChunkName: "pages-experience-home-mobile-nearby" */))
const pagesExperienceHomeMobileCategoryActivity2 = () => interopDefault(import('~/pages/experience/home/mobile/category-activity2.vue' /* webpackChunkName: "pages-experience-home-mobile-category-activity2" */))
const pagesExperienceActivityMobileIndex = () => interopDefault(import('~/pages/experience/activity/mobile/index.vue' /* webpackChunkName: "pages-experience-activity-mobile-index" */))
const pagesExperienceBookingOptionsNew = () => interopDefault(import('~/pages/experience/booking-options/new.vue' /* webpackChunkName: "pages-experience-booking-options-new" */))
const pagesExperienceBookingDetailsDesktopIndex = () => interopDefault(import('~/pages/experience/booking-details/desktop/index.vue' /* webpackChunkName: "pages-experience-booking-details-desktop-index" */))
const pagesExperienceBookingDetailsMobileIndex = () => interopDefault(import('~/pages/experience/booking-details/mobile/index.vue' /* webpackChunkName: "pages-experience-booking-details-mobile-index" */))
const pagesExperienceBookingDetailsDesktopIndexV2 = () => interopDefault(import('~/pages/experience/booking-details/desktop/index-v2.vue' /* webpackChunkName: "pages-experience-booking-details-desktop-index-v2" */))
const pagesExperienceBookingDetailsMobileIndexV2 = () => interopDefault(import('~/pages/experience/booking-details/mobile/index-v2.vue' /* webpackChunkName: "pages-experience-booking-details-mobile-index-v2" */))
const pagesExperienceExploreIndex = () => interopDefault(import('~/pages/experience/explore/index.vue' /* webpackChunkName: "pages-experience-explore-index" */))
const pagesExperienceExperienceActivityDesktopIndex = () => interopDefault(import('~/pages/experience/experience-activity/desktop/index.vue' /* webpackChunkName: "pages-experience-experience-activity-desktop-index" */))
const pagesExperienceExperienceActivityMobileIndex = () => interopDefault(import('~/pages/experience/experience-activity/mobile/index.vue' /* webpackChunkName: "pages-experience-experience-activity-mobile-index" */))
const pagesTravellerActivityBookingOptionsRedirect = () => interopDefault(import('~/pages/traveller/activity/booking-options/redirect.vue' /* webpackChunkName: "pages-traveller-activity-booking-options-redirect" */))
const pagesExperienceBookingSelectDesktopIndex = () => interopDefault(import('~/pages/experience/booking-select/desktop/index.vue' /* webpackChunkName: "pages-experience-booking-select-desktop-index" */))
const pagesExperienceManageBookingDesktopIndex = () => interopDefault(import('~/pages/experience/manage-booking/desktop/index.vue' /* webpackChunkName: "pages-experience-manage-booking-desktop-index" */))
const pagesExperienceManageBookingMobileIndex = () => interopDefault(import('~/pages/experience/manage-booking/mobile/index.vue' /* webpackChunkName: "pages-experience-manage-booking-mobile-index" */))
const pagesExperiencePayDesktopIndex = () => interopDefault(import('~/pages/experience/pay/desktop/index.vue' /* webpackChunkName: "pages-experience-pay-desktop-index" */))
const pagesExperiencePayMobileIndex = () => interopDefault(import('~/pages/experience/pay/mobile/index.vue' /* webpackChunkName: "pages-experience-pay-mobile-index" */))
const pagesExperienceShoppingCartDesktopIndex = () => interopDefault(import('~/pages/experience/shopping-cart/desktop/index.vue' /* webpackChunkName: "pages-experience-shopping-cart-desktop-index" */))
const pagesExperienceShoppingCartMobileIndex = () => interopDefault(import('~/pages/experience/shopping-cart/mobile/index.vue' /* webpackChunkName: "pages-experience-shopping-cart-mobile-index" */))
const pagesExperienceAdminPreviewIndex = () => interopDefault(import('~/pages/experience/admin-preview/index.vue' /* webpackChunkName: "pages-experience-admin-preview-index" */))
const pagesTourHomeDesktopActivityList = () => interopDefault(import('~/pages/tour/home/desktop/activity-list.vue' /* webpackChunkName: "pages-tour-home-desktop-activity-list" */))
const pagesTourHomeMobileActivityList = () => interopDefault(import('~/pages/tour/home/mobile/activity-list.vue' /* webpackChunkName: "pages-tour-home-mobile-activity-list" */))
const pagesAttractionsHomeDesktopIndex = () => interopDefault(import('~/pages/attractions/home/desktop/index.vue' /* webpackChunkName: "pages-attractions-home-desktop-index" */))
const pagesAttractionsHomeMobileIndex = () => interopDefault(import('~/pages/attractions/home/mobile/index.vue' /* webpackChunkName: "pages-attractions-home-mobile-index" */))
const pagesAttractionsListingDesktopIndex = () => interopDefault(import('~/pages/attractions/listing/desktop/index.vue' /* webpackChunkName: "pages-attractions-listing-desktop-index" */))
const pagesAttractionsListingMobileIndex = () => interopDefault(import('~/pages/attractions/listing/mobile/index.vue' /* webpackChunkName: "pages-attractions-listing-mobile-index" */))
const pagesAttractionsSearchDesktopIndex = () => interopDefault(import('~/pages/attractions/search/desktop/index.vue' /* webpackChunkName: "pages-attractions-search-desktop-index" */))
const pagesAttractionsSearchMobileIndex = () => interopDefault(import('~/pages/attractions/search/mobile/index.vue' /* webpackChunkName: "pages-attractions-search-mobile-index" */))

export async function createRouter(ssrContext) {
  let options = {
    mode: 'history',
    base: '/',
    fallback: false,
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [
      {
        path: '/404/',
        name: 'Error404',
        component: pagesCommonError404,
        pathToRegexpOptions: {"strict":true},
      },
      {
        path: '/401/',
        name: 'Error401',
        component: pagesCommonError404,
        pathToRegexpOptions: {"strict":true},
      },
      {
        path: '/500/',
        name: 'Error500',
        component: pagesCommonError500,
        pathToRegexpOptions: {"strict":true},
      },
      {
        path: '/apiHealthCheck/',
        name: 'apiHealthCheck',
        component: pagesCommonApiHealthCheck,
        pathToRegexpOptions: {"strict":true},
      },
      {
        path: '/experiences/locredirect/:frontendId(\\d+)/',
        name: 'ExperienceRedirect',
        components: {
          desktop: pagesExperienceHomeDesktopHome,
          mobile: pagesExperienceHomeMobileHome,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"redirect"},
      },
      {
        path: '/experiences-ttd/app-redirect/',
        name: 'ExperienceTtdAppRedirect',
        components: {
          desktop: pagesExperienceAppRedirectIndex,
          mobile: pagesExperienceAppRedirectIndex,
        },
        pathToRegexpOptions: {"strict":true},
      },
      {
        path: '/experiences/voucher-new-app-only/redeem/',
        name: 'ExperienceVoucherAppRedeem',
        components: {
          desktop: pagesCommonError404,
          mobile: pagesExperienceVoucherRedeemMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"VoucherRedeem"},
      },
      {
        path: '/experiences/',
        name: 'ExperienceAroundTheWorld',
        components: {
          desktop: pagesTourHomeDesktopHome,
          mobile: pagesTourHomeMobileHome,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"type":"world-l0","gaPageName":"Experience_Vertical","trackedPageName":"ExperiencesWorld","auth":false},
      },
      {
        path: '/experiences/mcate/:frontendId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceAroundTheWorldCategory',
        components: {
          desktop: pagesExperienceHomeDesktopHome,
          mobile: pagesExperienceHomeMobileHome,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"world-l1","gaPageName":"Experience_Vertical","trackedPageName":"ExperiencesWorld"},
      },
      {
        path: '/experiences/cate/:frontendId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceAroundTheWorldTag',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"world-l2","trackedPageName":"Experience_SubVertical","gaPageName":""},
      },
      {
        path: '/experiences/coureg/:countryId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceCountry',
        components: {
          desktop: pagesExperienceHomeDesktopHome,
          mobile: pagesExperienceHomeMobileHome,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"country-l0","gaPageName":"Experience_Vertical","trackedPageName":"ExperiencesCountry"},
      },
      {
        path: '/experiences/coureg-mcate/:countryId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceCountryCategory',
        components: {
          desktop: pagesExperienceHomeDesktopHome,
          mobile: pagesExperienceHomeMobileHome,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"country-l1","gaPageName":"Experience_Vertical","trackedPageName":"ExperiencesCountry"},
      },
      {
        path: '/experiences/coureg-cate/:countryId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceCountryTag',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"country-l2","trackedPageName":"Experience_SubVertical","gaPageName":"ExperiencesCountry"},
      },
      {
        path: '/experiences/city/:cityId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceCity',
        components: {
          desktop: pagesExperienceHomeDesktopHome,
          mobile: pagesExperienceHomeMobileHome,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"city-l0","gaPageName":"Experience_Vertical","trackedPageName":"ExperiencesCity"},
      },
      {
        path: '/experiences/city-mcate/:cityId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceCityCategory',
        components: {
          desktop: pagesExperienceHomeDesktopHome,
          mobile: pagesExperienceHomeMobileHome,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"city-l1","gaPageName":"Experience_Vertical","trackedPageName":"ExperiencesCity"},
      },
      {
        path: '/experiences/city-cate/:cityId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/',
        name: 'ExperienceCityTag',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"city-l2","trackedPageName":"Experience_SubVertical","gaPageName":""},
      },
      {
        path: '/experiences/search/',
        name: 'ExperienceSearch',
        components: {
          desktop: pagesExperienceHomeDesktopSearch,
          mobile: pagesExperienceHomeMobileSearch,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"search","trackedPageName":"ExperiencesSearch","gaPageName":""},
      },
      {
        path: '/experiences/nearby/',
        name: 'ExperienceNearby',
        components: {
          desktop: pagesExperienceHomeDesktopNearby,
          mobile: pagesExperienceHomeMobileNearby,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"ExperiencesNearBy","gaPageName":"Experience_Nearby"},
      },
      {
        path: '/experiences/mcate/:frontendId(\\d+):seo(-[^/]*)?/activity/',
        name: 'ExperienceCategoryActivityWorld',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"category-activity","trackedPageName":"Experience_SubVertical","gaPageName":"Experience_category_activity"},
      },
      {
        path: '/experiences/coureg-mcate/:countryId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/activity/',
        name: 'ExperienceCategoryActivityCountry',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"category-activity","trackedPageName":"Experience_SubVertical","gaPageName":"Experience_category_activity"},
      },
      {
        path: '/experiences/city-mcate/:cityId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/activity/',
        name: 'ExperienceCategoryActivityCity',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"category-activity","trackedPageName":"Experience_SubVertical","gaPageName":"Experience_category_activity"},
      },
      {
        path: '/experiences/city-mcate/:cityId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/activities/',
        name: 'ExperienceCategoryActivityCity2',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity2,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"category-activity","trackedPageName":"Experience_SubVertical","gaPageName":"Experience_category_activity"},
      },
      {
        path: '/experiences/city-cate/:cityId(\\d+)-:frontendId(\\d+):seo(-[^/]*)?/activities/',
        name: 'ExperienceCityTag2',
        components: {
          desktop: pagesExperienceHomeDesktopCategoryActivity,
          mobile: pagesExperienceHomeMobileCategoryActivity2,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"city-l2","trackedPageName":"Experience_SubVertical","gaPageName":""},
      },
      {
        path: '/:preview(preview)?/experiences/activity/:activityPath/',
        name: 'ExperienceActivityHome',
        components: {
          desktop: pagesCommonError404,
          mobile: pagesExperienceActivityMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"gaPageName":"ExperiencesActivityPage","trackedPageName":"Activity","auth":false},
      },
      {
        path: '/:nuxt_page(nuxt_page)?/experiences/tobook/new/:activityId/',
        name: 'ExperienceActivityBookOptions',
        components: {
          desktop: pagesCommonError404,
          mobile: pagesExperienceBookingOptionsNew,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"gaPageName":"BookingPage","trackedPageName":"BookingOptions","auth":false},
      },
      {
        path: '/experiences/booking-details/',
        name: 'ExperienceBookingDetails',
        components: {
          desktop: pagesExperienceBookingDetailsDesktopIndex,
          mobile: pagesExperienceBookingDetailsMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"BookingDetail"},
      },
      {
        path: '/experiences/booking_detail/',
        name: 'ExperienceV2BookingDetails',
        components: {
          desktop: pagesExperienceBookingDetailsDesktopIndexV2,
          mobile: pagesExperienceBookingDetailsMobileIndexV2,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"BookingDetail"},
      },
      {
        path: '/experiences/explore/:slug?/:themeId/',
        name: 'ExperiencesExplorePage',
        components: {
          desktop: pagesExperienceExploreIndex,
          mobile: pagesExperienceExploreIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"gaPageName":"TNA_Explore","trackedPageName":"TNA_Explore","auth":false},
      },
      {
        path: '/:preview(preview)?/activity/:activityPath/',
        name: 'ActivityHome',
        components: {
          desktop: pagesExperienceExperienceActivityDesktopIndex,
          mobile: pagesExperienceExperienceActivityMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"gaPageName":"ActivityPage","trackedPageName":"Activity","auth":false},
      },
      {
        path: '/tobook/new/:activityId/',
        name: 'ActivityBookOptions',
        components: {
          desktop: pagesCommonError404,
          mobile: pagesTravellerActivityBookingOptionsRedirect,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"gaPageName":"BookingPage"},
      },
      {
        path: '/experiences/booking-select/',
        name: 'ExperienceBookingSelect',
        components: {
          desktop: pagesExperienceBookingSelectDesktopIndex,
          mobile: pagesCommonError404,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"Reservation"},
      },
      {
        path: '/experiences/manage-booking/',
        name: 'ExperienceManageBooking',
        components: {
          desktop: pagesExperienceManageBookingDesktopIndex,
          mobile: pagesExperienceManageBookingMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"ManageBooking"},
      },
      {
        path: '/experiences/pay/',
        name: 'ExperiencePay',
        components: {
          desktop: pagesExperiencePayDesktopIndex,
          mobile: pagesExperiencePayMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"TTD_Payment"},
      },
      {
        path: '/shoppingcart/',
        name: 'ExperienceShoppingCart',
        components: {
          desktop: pagesExperienceShoppingCartDesktopIndex,
          mobile: pagesExperienceShoppingCartMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"ShoppingCart"},
      },
      {
        path: '/experiences/voucher/redeem/',
        name: 'ExperienceVoucherRedeem',
        components: {
          desktop: pagesCommonError404,
          mobile: pagesExperienceVoucherRedeemMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"trackedPageName":"VoucherRedeem"},
      },
      {
        path: '/experiences/admin_preview/:activityPath/',
        name: 'ExperienceAdminPreview',
        components: {
          desktop: pagesExperienceAdminPreviewIndex,
          mobile: pagesExperienceAdminPreviewIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"auth":false},
      },
      {
        path: '/experiences/list/:seoLink?/:id?/',
        name: 'TourActivityList',
        components: {
          desktop: pagesTourHomeDesktopActivityList,
          mobile: pagesTourHomeMobileActivityList,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"gaPageName":"TNA_Activity_List","auth":false},
      },
      {
        path: '/attractions/',
        name: 'AttractionsVerticalHome',
        components: {
          desktop: pagesAttractionsHomeDesktopIndex,
          mobile: pagesAttractionsHomeMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"type":"world-l0","gaPageName":"Attraction_Vertical","trackedPageName":"Attraction_Vertical","auth":false},
      },
      {
        path: '/attractions/:country/g:countryId(\\d+)/',
        name: 'AttractionsVerticalCountry',
        components: {
          desktop: pagesAttractionsListingDesktopIndex,
          mobile: pagesAttractionsListingMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"type":"country-l0","gaPageName":"Attraction_ActivityList","trackedPageName":"Attraction_ActivityList","auth":false},
      },
      {
        path: '/attractions/:city/c:cityId(\\d+)/',
        name: 'AttractionsVerticalCity',
        components: {
          desktop: pagesAttractionsListingDesktopIndex,
          mobile: pagesAttractionsListingMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"type":"city-l0","gaPageName":"Attraction_ActivityList","trackedPageName":"Attraction_ActivityList","auth":false},
      },
      {
        path: '/attractions/:category/cate:frontendId(\\d+)/',
        name: 'AttractionsVerticalCategory',
        components: {
          desktop: pagesAttractionsListingDesktopIndex,
          mobile: pagesAttractionsListingMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"type":"world-l2","trackedPageName":"Attraction_ActivityList","gaPageName":"Attraction_ActivityList","auth":false},
      },
      {
        path: '/attractions/:country-:category/g:countryId(\\d+)-cate:frontendId(\\d+)/',
        name: 'AttractionsVerticalCategoryCountry',
        components: {
          desktop: pagesAttractionsListingDesktopIndex,
          mobile: pagesAttractionsListingMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"type":"country-l2","trackedPageName":"Attraction_ActivityList","gaPageName":"Attraction_ActivityList","auth":false},
      },
      {
        path: '/attractions/:city-:category/c:cityId(\\d+)-cate:frontendId(\\d+)/',
        name: 'AttractionsVerticalCategoryCity',
        components: {
          desktop: pagesAttractionsListingDesktopIndex,
          mobile: pagesAttractionsListingMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"showOta":true,"type":"city-l2","trackedPageName":"Attraction_ActivityList","gaPageName":"Attraction_ActivityList","auth":false},
      },
      {
        path: '/attractions/search/',
        name: 'AttractionsSearch',
        components: {
          desktop: pagesAttractionsSearchDesktopIndex,
          mobile: pagesAttractionsSearchMobileIndex,
        },
        pathToRegexpOptions: {"strict":true},
        meta: {"type":"search","trackedPageName":"AttractionsSearch","gaPageName":"","auth":false},
      },
    ],
  }

  try {
    const { createRouter: customCreateRouter } = require('../router')
    options = await customCreateRouter(ssrContext, options)
  } catch (error) {
    // Ignore
  }

  return new Router(options)
}
