import { Plugin } from '@nuxt/types'
import { nuxtPlugin } from '@klook/sentry-vue'

// plugin已内置一部分ignoreErrors
const ignoreErrors = [
  /^zaloJSV2 is not defined$/,
  /^kakaoPixel is not defined$/
]

const debugKey = '__experiences__sentry'

const plugin: Plugin = (ctx, inject) => {
  const { app, store } = ctx
  const klook = store.state.klook as Data.Klook
  const isInitSdk = klook.market !== 'cn' && process.client
  const sentryCustomSpanMap: any = {}
  if (isInitSdk) {
    nuxtPlugin({
      // 按需设置
      dsn: process.env.SENTRY_DSN,
      environment: process.env.SENTRY_ENV || process.env.APP_ENV,
      sampleRate: +process.env.SENTRY_SAMPLE_RATE!,
      tracesSampleRate: +process.env.SENTRY_TRANCES_SAMPLE_RATE!,
      debug: localStorage.getItem(debugKey) === 'true',
      ignoreErrors,
      beforeSend(event, hint) {
        if (localStorage.getItem(debugKey)) {
          console.log('sentry beforeSend event:', event, 'hint:', hint)
        }
        return event
      }
    }, ctx, inject)
  } else {
    nuxtPlugin(false, ctx, inject)
  }

  /**
   * 上报sentry事件，封装方法支持将异常事件打在面包屑中，便于后续排查异常事务的异常原因
   * @param event 异常事件
   */
  function captureSenrtyEvent(event: any) {
    if (app.$sentry) {
      if (process.env.IS_LOCALHOST_DEV) {
        console.info('captureSenrtyEvent', event)
      }
      // 当异常发生时自动将异常信息搭载面包屑上
      app.$sentry.addBreadcrumb({
        message: event.message,
        level: event.level,
        type: 'error',
        data: event.extra // 嵌套层级不支持超过三层
      })
      // error 及 fatal级别的错误会修改事务状态，最终影响页面阻塞率
      app.$sentry.reportEvent(event.message, event.tags, ['error', 'fatal'].includes(event.level) ? 'internal_error' : null, event)
    }
  }

  /**
   * 开启自定义非活跃span（非活跃span需要手动开始和结束才能正常上报）
   * @param status 开启/关闭
   * @param name span名称
   * @param options span信息
   */
  function setSentryCustomSpan(status: 'start' | 'end', name: string, options?: any) {
    if (app.$sentry) {
      if (status === 'start') {
        sentryCustomSpanMap[name] = app.$sentry.startInactiveSpan({ name, ...options })
      } else if (sentryCustomSpanMap[name].finish) {
        sentryCustomSpanMap[name].finish()
        delete sentryCustomSpanMap[name]
      }
    }
  }

  inject('setSentryCustomSpan', setSentryCustomSpan)

  inject('captureSenrtyEvent', captureSenrtyEvent)
}

export default plugin
