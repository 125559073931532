import { Plugin } from '@nuxt/types'
import Axios, { AxiosResponse } from 'axios'
import get from 'lodash/get'
import { formatReq, formatRes, formatError, formatToOptimus, setOpenHarmonyParams } from './utils'
import { showLoading, hideLoading } from './loading'
import { guestApiList } from '~/share/data/apis'
import { randomString, getQueryKey, getLoginUrl } from '~/share/utils'
import { match } from '~/share/api.config'

/**
 * Attach some methods, e.g: $get, $post, ...
 * @param instance Axios instance
 */
function attachMethods(instance: any) {
  const methods = ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']

  for (const method of methods) {
    instance['$' + method] = function (this: any) {
      return this[method]
        .apply(this, arguments)
        .then((res: AxiosResponse) => res && res.data)
    }
  }
}

function setParamsLangCurrency(ctx: any, config: any) {
  const { klook } = ctx.store.state || {}
  if (config.method.toLocaleLowerCase() !== 'get' || !process.client || config.paramsAddLangCurrency === false) {
    // 目前仅支持：客户端、get请求，paramsAddLangCurrency设置为false可关闭追加参数
    return
  }
  const { params } = config || {}
  const newParams = {
    k_lang: klook.backendLanguage,
    k_currency: klook.currency,
    ...(params || {})
  }
  config.params = newParams
  return newParams
}

const axiosTimeout = process.client ? 30000 : 6000
const baseURL = process.client ? process.env.API_URL_BROWSER : process.env.API_URL

const plugin: Plugin = function plugin(ctx, inject) {
  const { app, store, req } = ctx
  const axios = Axios.create({
    baseURL,
    timeout: axiosTimeout,
    withCredentials: false,
    headers: {
      Version: '6.0',
      Accept: 'application/json, text/plain, */*',
      'X-Requested-With': 'XMLHttpRequest'
    }
  })

  axios.CancelToken = Axios.CancelToken
  axios.isCancel = Axios.isCancel
  attachMethods(axios)

  // Setting request hook
  axios.interceptors.request.use(
    async function requestSuccess(config) {
      setParamsLangCurrency(ctx, config)
      const { headers, noCache = true, CSRF = false } = config
      const { klook, common: { kepler } } = store.state
      const tintedList = process.server ? req.tintedList : (kepler.tintedList || '')
      const isGuestCheckout = klook.utilConfig.is_guest_checkout
      const keplerId = process.server ? (req.keplerId || '') : (kepler.keplerId || '')
      // Setting custom header
      headers['Accept-Language'] = klook.backendLanguage || ''
      headers.Currency = klook.currency || ''
      headers.Token = app.$cookies.get('_pt') || ''
      headers._pt = app.$cookies.get('device_id_new') || keplerId || ''
      headers['X-Klook-Kepler-Id'] = keplerId
      headers['X-Klook-Tint'] = tintedList || ''
      headers['X-Klook-Page-Open-Id'] = (process.server ? req.pageId : klook.pageId) || ''
      headers['X-Klook-Host'] = klook.host || ''
      // 有一个项目要用，后端通过 aid, pid 做一些判断。具体是那个项目，后端记得不是很清楚 @haifeng.shi
      // 应该是一个 aid 限制优惠码的项目
      headers['X-Klook-Affiliate-Aid'] = get(klook.affiliateConf, 'wid', '')
      headers['X-Klook-Affiliate-Pid'] = get(klook.affiliateConf, 'aid_extra_info.aff_pid', '')
      // 后端 l10n cms debug mode
      const debugModeValue = app.$i18n.options.isDebugMode ? 'text' : ''

      let channel = req?.trafficChannel

      if (process.client) {
        const state = app.$traffic.useState({
          type: app.$traffic.constant.TrafficChannel
        })
        channel = state.channel?.channel
      }

      if (channel) {
        headers['X-Klook-Traffic-Channel'] = channel
      }

      if (debugModeValue) {
        headers['X-Klook-Debug-Mode'] = debugModeValue
      }

      // residence
      const countryCode = getQueryKey('x_user_residence', ctx.query) as Data.Country // 格式：x_user_residence=2_HK
      if (klook.residenceId || countryCode) {
        headers['X-Klook-User-Residence'] = countryCode || `${klook.residenceId}_${klook.residenceCode}`
      }

      // Market
      headers['X-Klook-Market'] = klook.market || 'global'

      if (noCache) {
        // 缓存接口会导致切换语言时接口返回多语言不正确
        headers['Cache-Control'] = 'no-cache'
      }

      // 后端写cookie的时候加了 secure，前端这边本地无需打开csrf验证
      if (process.client && CSRF && process.env.APP_ENV !== 'development') {
        // 开启csrf
        let csrfToken = app.$cookies.get('CSRF-Token') || ''
        if (!csrfToken) {
          await csrfTokenValidCheck()
          csrfToken = app.$cookies.get('CSRF-Token') || ''
        }
        headers['X-CSRF-Token'] = csrfToken
      }

      async function csrfTokenValidCheck() {
        const csrfTokenValid = app.$cookies.get('CSRF-Token-Valid') && app.$cookies.get('CSRF-Token')

        if (!csrfTokenValid) {
          await axios.get('/v1/gatewaysrv/csrftoken')
        }
      }

      if (klook.isOpenHarmony) {
        headers['X-Platform'] = 'openharmony-shell' // 鸿蒙内嵌mweb特殊处理
        setOpenHarmonyParams(config)
      } else {
        headers['X-Platform'] = headers['X-Platform'] || klook.platform || '' // web中platform只能是mobile和desktop
      }

      // 开发环境取到的ip是127.0.0.1
      const env = process.env.NODE_ENV || 'development'
      if (process.server && env !== 'development') {
        // 设置 x-forwarded-for 转发客户端ip
        const xForwardedForHealthCheck = req.headers['x-forwarded-for-health-check']

        const realIp = req.headers['X-Real-IP']
        const ips = req.ips
        const ipsStr = Array.isArray(ips) && ips.length ? ips.join(',') : ''
        headers['X-Forwarded-For'] = xForwardedForHealthCheck || ipsStr || req.ip || '0.0.0.0'
        headers['X-Real-IP'] = req.ip || realIp || '0.0.0.0'
      }

      if (process.server) {
        headers['X-REQ-CLIENT'] = 'klook-nuxt-web'
        headers.Referer = req.headers.referer || ''
        headers['User-Agent'] = req.headers['user-agent'] || ''
        headers.Cookie = req.headers.cookie || ''
        headers['X-DeviceID'] = req.headers['x-deviceid'] || ''
        headers['X-Request-From'] = 'node'

        config.baseURL = match(config.url!)
      }

      // change prefix of the api if it is guest checkout mode
      if (isGuestCheckout && guestApiList.includes(config.url || '')) {
        config.baseURL = process.server
          ? `http://127.0.0.1:${req.socket.localPort}/xos_gc`
          : '/xos_gc'
      }

      // Internal fields
      config.startTime = Date.now()
      config.fullURL = (config.baseURL || '').replace(/\/$/, '') + config.url
      config.requestId = process.server
        ? `${req.requestId}-${randomString()}`
        : randomString()

      showLoading(config)

      if (process.server) {
        const logData = {
          requestId: config.requestId,
          method: config.method!,
          url: config.fullURL
        }
        app.$logger.info(formatReq(logData))
        if (req.logquery) {
          req.logquery.service({
            level: 'I',
            funcName: 'axios.plugin.requestSuccess',
            isMasked: true,
            message: logData
          })
        }
      }
      return config
    },
    function requestError(error) {
      if (process.server) {
        if (req?.logquery?.service) {
          req.logquery.service({
            level: 'E',
            funcName: 'axios.plugin.requestError',
            isMasked: true,
            message: {
              href: req.href,
              url: req.url,
              method: req.method,
              keplerId: req.keplerId,
              requestId: req.requestId,
              errMsg: error.message,
              error
            }
          })
        }
      }
      return Promise.reject(error)
    }
  )

  // Setting response hook
  axios.interceptors.response.use(
    function responseSuccess(response) {
      const { data, config, status, request } = response

      hideLoading(config)

      if (typeof data === 'object' && !data.success) {
        data.error = data.error || {
          code: '',
          message: app.$i18n.t('client_server_failure')
        }

        if (config.throwError) {
          const { code, message, ...others } = data.error
          const error = new Error(message)
          const errorType = 'service'

          if (process.server) {
            const logData = {
              requestId: config.requestId!,
              method: config.method!,
              url: config.fullURL!,
              status: code,
              elapsedTime: Date.now() - config.startTime!,
              message
            }
            app.$logger.error(formatError(logData))
            if (req.logquery) {
              req.logquery.service({
                level: 'E',
                funcName: 'axios.plugin.responseSuccess.throwError',
                message: logData
              })
            }
          }

          return Promise.reject(Object.assign(error, {
            ...others,
            code,
            data,
            config,
            errorType
          }))
        }
      }
      if (process.server) {
        if (req.logquery) {
          const errorCode = data?.error?.code || 0
          req.logquery.service({
            level: 'I',
            funcName: 'axios.plugin.responseSuccess',
            message: formatToOptimus({ config, response, request, ctx }, {
              code: String(errorCode)
            })
          })
        } else {
          const logData = {
            requestId: config.requestId!,
            method: config.method!,
            url: config.fullURL!,
            status: String(status),
            elapsedTime: Date.now() - config.startTime!
          }
          app.$logger.info(formatRes(logData))
        }
      }

      if (!process.server && ctx.app.$clientReport) {
        ctx.app.$clientReport.reportRequestSuccess({
          pageName: Object.assign({}, ...ctx.route.meta).trackedPageName
        }, config, response)
      }

      if (!process.server && config?.checkLogin && Number(data?.error?.code) === 4001) {
        data.error.message = ''
        window.location.href = getLoginUrl({ klook: ctx.store.state.klook, fullPath: ctx.route.fullPath })
      }

      return response
    },
    function responseError(error) {
      // 手动取消的错误直接return 不上报
      if (!axios.isCancel(error)) {
        const { message, config, response, request } = error

        hideLoading(config)

        if (response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { data, status } = response

          error.errorType = 'network'
          error.code = (data.error && data.error.code) || String(status)
          error.message = (data.error && data.error.message) || app.$i18n.t('client_server_failure') as string
        } else if (request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          error.code = '990001'
          error.errorType = 'client'
          error.message = app.$i18n.t('client_network_failure') as string
        } else {
          // Something happened in setting up the request that triggered an Error
          error.code = '990001'
          error.errorType = 'client'
          error.message = app.$i18n.t('client_network_failure') as string // Overwrite actual message
        }

        if (process.server) {
          const logData = {
            requestId: config?.requestId,
            method: config?.method || '',
            url: config?.fullURL,
            rawUrl: config?.url,
            status: error.code,
            elapsedTime: Date.now() - config?.startTime!,
            message
          }
          app.$logger.error(formatError(logData))
          if (req.logquery) {
            const errorCode = error.code || 0
            req.logquery.service({
              level: 'E',
              file: __filename,
              funcName: 'axios.plugin.responseError',
              message: formatToOptimus({ config, response, request, ctx }, {
                level: 'E',
                code: String(errorCode)
              })
            })
          }
        }

        if (!process.server && ctx.app.$clientReport) {
          ctx.app.$clientReport.reportRequestError({
            pageName: Object.assign({}, ...ctx.route.meta).trackedPageName
          }, config, response, error)
        }
      }

      return Promise.reject(error)
    }
  )

  inject('axios', axios)
}

export default plugin
